/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  fullname: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  role: UserCreateRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  password: string;

  confirmPassword: string;
}

export function UserCreateFromJSON(json: any): UserCreate {
  return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    fullname: json["fullname"],
    role: json["role"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    password: json["password"],
    confirmPassword: json["confirm_password"],
  };
}

export function UserCreateToJSON(value?: UserCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    fullname: value.fullname,
    role: value.role,
    customer_id: value.customerId,
    password: value.password,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum UserCreateRoleEnum {
  Root = "root",
  Admin = "admin",
  Readonly = "readonly",
}
