/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Meeting
 */
export interface Meeting {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof Meeting
   */
  readonly id?: string;
  /**
   * Due date
   * @type {Date}
   * @memberof Meeting
   */
  meetingDate: Date;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  meetingType: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  present: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  discussion: string;
  /**
   *
   * @type {string}
   * @memberof Meeting
   */
  actions: string;
  /**
   *
   * @type {boolean}
   * @memberof Meeting
   */
  sentToCrew: boolean;
  /**
   *
   * @type {Date}
   * @memberof Meeting
   */
  dateSent: Date;
  /*
   *
   * @type {Date}
   * @memberof Meeting
   */
  readonly created?: Date;
}

export function MeetingFromJSON(json: any): Meeting {
  return MeetingFromJSONTyped(json, false);
}

export function MeetingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Meeting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    meetingDate:
      !exists(json, "meeting_date") || !json["meeting_date"]
        ? undefined
        : new Date(json["meeting_date"]),
    location: !exists(json, "location") ? undefined : json["location"],
    meetingType: !exists(json, "meeting_type")
      ? undefined
      : json["meeting_type"],
    present: !exists(json, "present") ? "" : json["present"],
    discussion: !exists(json, "discussion") ? "" : json["discussion"],
    actions: !exists(json, "actions") ? "" : json["actions"],
    sentToCrew: !exists(json, "sent_to_crew") ? false : json["sent_to_crew"],
    dateSent:
      !exists(json, "date_sent") || !json["date_sent"]
        ? undefined
        : new Date(json["date_sent"]),
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function MeetingToJSON(value?: Meeting | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  return {
    id: !value.id ? undefined : value.id,
    location: value.location,
    meeting_date: !value.meetingDate
      ? ""
      : value.meetingDate.toString() == "Invalid Date"
      ? ""
      : value.meetingDate.toISOString(),
    meeting_type: value.meetingType,
    present: value.present,
    discussion: value.discussion,
    actions: value.actions,
    sent_to_crew: value.sentToCrew,
    date_sent: !value.dateSent
      ? ""
      : value.dateSent.toString() == "Invalid Date"
      ? ""
      : value.dateSent.toISOString(),
  };
}
