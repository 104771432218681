/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Repair
 */
export interface Repair {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof Repair
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Repair
   */
  description: string;

  /**
   * Repair reported date
   * @type {Date}
   * @memberof Repair
   */
  reportedDate?: Date;

  /**
   *
   * @type {string}
   * @memberof Repair
   */
  reportedBy?: string;

  /**
   *
   * @type {string}
   * @memberof Repair
   */
  requiredAction?: string;

  /**
   *
   * @type {number}
   * @memberof Repair
   */
  priority?: number;

  /**
   *
   * @type {Date}
   * @memberof Repair
   */
  dueDate?: Date;

  /**
   *
   * @type {Date}
   * @memberof Repair
   */
  dateCompleted?: Date;

  /**
   *
   * @type {string}
   * @memberof Repair
   */
  signedOffBy?: string;

  /**
   *
   * @type {string}
   * @memberof Repair
   */
  vesselId: string;

  /**
   *
   * @type {boolean}
   * @memberof Repair
   */
  overdue?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof Repair
   */
  upcoming?: boolean;

  /**
   *
   * @type {Date}
   * @memberof Repair
   */
  readonly created?: Date;
}

export function RepairFromJSON(json: any): Repair {
  return RepairFromJSONTyped(json, false);
}

export function RepairFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Repair {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    description: json["description"],
    reportedDate: !json["reported_date"] ? "" : new Date(json["reported_date"]),
    reportedBy: json["reported_by"],
    priority: parseInt(json["priority"]),
    requiredAction: json["required_action"],
    dueDate: !json["due_date"] ? "" : new Date(json["due_date"]),
    dateCompleted:
      !exists(json, "date_completed") || !json["date_completed"]
        ? undefined
        : new Date(json["date_completed"]),
    signedOffBy: json["signed_off_by"],
    vesselId: json["vessel_id"],
    overdue: !json["overdue"] ? "" : json["overdue"],
    upcoming: !json["upcoming"] ? "" : json["upcoming"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function RepairToJSON(value?: Repair | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    reported_date: !value.reportedDate
      ? undefined
      : value.reportedDate.toISOString(),
    reported_by: !value.reportedBy ? undefined : value.reportedBy,
    priority: value.priority,
    required_action: value.requiredAction,
    due_date: !value.dueDate ? undefined : value.dueDate.toISOString(),
    date_completed: !value.dateCompleted
      ? undefined
      : value.dateCompleted.toISOString(),
    signed_off_by: !value.signedOffBy ? undefined : value.signedOffBy,
    vessel_id: value.vesselId,
    //  overdue: value.overdue,
    //  upcoming: value.upcoming,
  };
}
