/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Document,
  DocumentFromJSON,
  DocumentToJSON,
  DocumentPagination,
  DocumentPaginationFromJSON,
  DocumentPaginationToJSON,
} from "../models";

export interface GetCrewMemberDocumentListRequest {
  page?: number;
  perPage?: number;
  objId?: string;
  docType?: string;
  replaceDoc?: boolean;
  customerId?: string;
  crewId?: string;
}

export interface GetDocumentListRequest {
  page?: number;
  perPage?: number;
  objId?: string;
  docType?: string;
  customerId?: string;
  vesselId?: string;
}

export interface PostCrewMemberDocumentListRequest {
  file: Blob;
  objId?: string;
  docType?: string;
  fileName?: string;
  replaceDoc?: boolean;
  crewId?: string;
}

export interface PostDocumentListRequest {
  file: Blob;
  objId?: string;
  docType?: string;
  fileName?: string;
  replaceDoc?: boolean;
  vesselId?: string;
}

export interface DeleteDocumentItemRequest {
  id: string;
}

/**
 *
 */
export class DocumentApi extends runtime.BaseAPI {
  /**
   * Get a list of documents for a specific crew member
   */
  async getCrewMemberDocumentListRaw(
    requestParameters: GetCrewMemberDocumentListRequest
  ): Promise<runtime.ApiResponse<DocumentPagination>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.objId !== undefined) {
      queryParameters["obj_id"] = requestParameters.objId;
    }

    if (requestParameters.docType !== undefined) {
      queryParameters["doc_type"] = requestParameters.docType;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    if (requestParameters.crewId !== undefined) {
      queryParameters["crew_id"] = requestParameters.crewId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/crewdocument/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DocumentPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of documents for a specific crew member
   */
  async getCrewMemberDocumentList(
    requestParameters: GetCrewMemberDocumentListRequest
  ): Promise<DocumentPagination> {
    const response = await this.getCrewMemberDocumentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of documents for a specific vessel
   */
  async getDocumentListRaw(
    requestParameters: GetDocumentListRequest
  ): Promise<runtime.ApiResponse<DocumentPagination>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.objId !== undefined) {
      queryParameters["obj_id"] = requestParameters.objId;
    }

    if (requestParameters.docType !== undefined) {
      queryParameters["doc_type"] = requestParameters.docType;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesseldocument/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DocumentPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get a list of documents for a specific vessel
   */
  async getDocumentList(
    requestParameters: GetDocumentListRequest
  ): Promise<DocumentPagination> {
    const response = await this.getDocumentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Document will be associated to specified crew member. crew_id can be informed as form data or query parameter.
   * Create a new document attached to a specific crew member
   */
  async postCrewMemberDocumentListRaw(
    requestParameters: PostCrewMemberDocumentListRequest
  ): Promise<runtime.ApiResponse<Document>> {
    if (
      requestParameters.file === null ||
      requestParameters.file === undefined
    ) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling postCrewMemberDocumentList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.objId !== undefined) {
      queryParameters["obj_id"] = requestParameters.objId;
    }

    if (requestParameters.docType !== undefined) {
      queryParameters["doc_type"] = requestParameters.docType;
    }

    if (requestParameters.fileName !== undefined) {
      queryParameters["file_name"] = requestParameters.fileName;
    }

    if (requestParameters.crewId !== undefined) {
      queryParameters["crew_id"] = requestParameters.crewId;
    }

    if (requestParameters.replaceDoc !== undefined) {
      queryParameters["replace_doc"] = requestParameters.replaceDoc;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/crewdocument/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(
      response,
      (jsonValue) =>
        // DocumentFromJSON(jsonValue)
        jsonValue
    );
  }

  /**
   * Document will be associated to specified crew member. crew_id can be informed as form data or query parameter.
   * Create a new document attached to a specific crew member
   */
  async postCrewMemberDocumentList(
    requestParameters: PostCrewMemberDocumentListRequest
  ): Promise<Document> {
    const response = await this.postCrewMemberDocumentListRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Document will be associated to specified vessel. vessel_id can be informed as form data or query parameter.
   * Create a new document and attach it to a specific action and vessel
   */
  async postDocumentListRaw(
    requestParameters: PostDocumentListRequest
  ): Promise<runtime.ApiResponse<Document>> {
    if (
      requestParameters.file === null ||
      requestParameters.file === undefined
    ) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling postDocumentList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.objId !== undefined) {
      queryParameters["obj_id"] = requestParameters.objId;
    }

    if (requestParameters.docType !== undefined) {
      queryParameters["doc_type"] = requestParameters.docType;
    }

    if (requestParameters.fileName !== undefined) {
      queryParameters["file_name"] = requestParameters.fileName;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.replaceDoc !== undefined) {
      queryParameters["replace_doc"] = requestParameters.replaceDoc;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/vesseldocument/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(
      response,
      (jsonValue) =>
        // DocumentFromJSON(jsonValue)
        jsonValue
    );
  }

  /**
   * Document will be associated to specified vessel. vessel_id can be informed as form data or query parameter.
   * Create a new document and attach it to a specific action and vessel
   */
  async postDocumentList(
    requestParameters: PostDocumentListRequest
  ): Promise<Document> {
    const response = await this.postDocumentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete vessel document
   * @param requestParameters
   * @returns
   */
  async deleteDocumentItemRaw(
    requestParameters: DeleteDocumentItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (!requestParameters.id) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.id was null or undefined when calling deleteDocumentItem."
      );
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesseldocument/${requestParameters.id}`,
      method: "DELETE",
      headers: headerParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  async deleteDocumentItem(
    requestParameters: DeleteDocumentItemRequest
  ): Promise<void> {
    await this.deleteDocumentItemRaw(requestParameters);
  }

  /**
   * Delete crew document
   * @param requestParameters
   * @returns
   */
  async deleteCrewMemberDocumentItemRaw(
    requestParameters: DeleteDocumentItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (!requestParameters.id) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.id was null or undefined when calling deleteDocumentItem."
      );
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/crewdocument/${requestParameters.id}`,
      method: "DELETE",
      headers: headerParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  async deleteCrewMemberDocumentItem(
    requestParameters: DeleteDocumentItemRequest
  ): Promise<void> {
    await this.deleteCrewMemberDocumentItemRaw(requestParameters);
  }
}
