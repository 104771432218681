/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { Feedback, FeedbackFromJSON, FeedbackToJSON } from "../models";

export interface DeleteFeedbackRequest {
  id: string;
}

export interface GetFeedbackRequest {
  id: string;
}

export interface PutFeedbackRequest {
  id: string;
  payload: Feedback;
}

export interface PutFeedbackRequest {
  id: string;
  payload: Feedback;
}

export interface PostFeedbackRequest {
  payload: Feedback;
}

/**
 *
 */
export class FeedbackApi extends runtime.BaseAPI {
  /**
   * Create new Feedback
   */
  async postFeedbackRaw(
    requestParameters: PostFeedbackRequest
  ): Promise<runtime.ApiResponse<Feedback>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postFeedback."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/form/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FeedbackToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FeedbackFromJSON(jsonValue)
    );
  }

  /**
   * Create new Feedback
   */
  async postFeedback(
    requestParameters: PostFeedbackRequest
  ): Promise<Feedback> {
    const response = await this.postFeedbackRaw(requestParameters);
    return await response.value();
  }
}
