/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Review
 */
export interface Review {
  /**
   * Review unique identifier.
   * @type {string}
   * @memberof Review
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Review
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Review
   */
  notes: string;
  /**
   * Due date
   * @type {Date}
   * @memberof Review
   */
  dueDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Review
   */
  readonly overdue?: boolean;
  /**
   * Due date
   * @type {Date}
   * @memberof Review
   */
  completedDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Review
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof Review
   */
  readonly customerId?: string;
  /**
   *
   * @type {Date}
   * @memberof Review
   */
  readonly created?: Date;
}

export function ReviewFromJSON(json: any): Review {
  return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Review {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    notes: json["notes"],
    dueDate:
      !exists(json, "due_date") || !json["due_date"]
        ? undefined
        : new Date(json["due_date"]),
    overdue: !exists(json, "overdue") ? undefined : json["overdue"],
    completedDate:
      !exists(json, "completed_date") || !json["completed_date"]
        ? undefined
        : new Date(json["completed_date"]),
    vesselId: json["vessel_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function ReviewToJSON(value?: Review | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    notes: !value.notes ? "" : value.notes,
    due_date: !value.dueDate ? "" : value.dueDate.toISOString(),
    completed_date: !value.completedDate
      ? ""
      : value.completedDate.toISOString(),
    vessel_id: value.vesselId,
  };
}
