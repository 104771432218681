/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReturnToken
 */
export interface ReturnToken {
  /**
   *
   * @type {string}
   * @memberof ReturnToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof ReturnToken
   */
  refreshToken: string;
}

export function ReturnTokenFromJSON(json: any): ReturnToken {
  return ReturnTokenFromJSONTyped(json, false);
}

export function ReturnTokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReturnToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json['access_token'],
    refreshToken: json['refresh_token'],
  };
}

export function ReturnTokenToJSON(value?: ReturnToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_token: value.accessToken,
    refresh_token: value.refreshToken,
  };
}
