/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { UserSetting, UserSettingFromJSON, UserSettingToJSON } from "../models";

export interface GetUserSettingRequest {
  id: string;
}
export interface PutUserSettingRequest {
  id: string;
  payload: UserSetting;
}

/**
 *
 */
export class UserSettingApi extends runtime.BaseAPI {
  /**
   * Get user settings
   */
  async getUserSettingRaw(
    requestParameters: GetUserSettingRequest
  ): Promise<runtime.ApiResponse<UserSetting>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getUserSetting."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/usersettings/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSettingFromJSON(jsonValue)
    );
  }

  /**
   * Get user settings
   */
  async getUserSetting(
    requestParameters: GetUserSettingRequest
  ): Promise<UserSetting> {
    const response = await this.getUserSettingRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update user setting
   */
  async putUserSettingRaw(
    requestParameters: PutUserSettingRequest
  ): Promise<runtime.ApiResponse<UserSetting>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putUserSetting."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putUserSetting."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/usersettings/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UserSettingToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSettingFromJSON(jsonValue)
    );
  }

  /**
   * Update user setting
   */
  async putUserSetting(
    requestParameters: PutUserSettingRequest
  ): Promise<UserSetting> {
    const response = await this.putUserSettingRaw(requestParameters);
    return await response.value();
  }
}
