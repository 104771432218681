/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Feedback
 */
export interface Feedback {
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  vesselId: string;
  /**
   *
   * @type {Date}
   * @memberof Feedback
   */
  readonly created?: Date;
}

export function FeedbackFromJSON(json: any): Feedback {
  return FeedbackFromJSONTyped(json, false);
}

export function FeedbackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Feedback {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: json["description"],
    userId: json["user_id"],
    vesselId: json["vessel_id"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function FeedbackToJSON(value?: Feedback | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    vessel_id: value.vesselId,
    user_id: value.userId,
  };
}
