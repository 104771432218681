/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Part
 */
export interface Part {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof Part
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Part
   */
  name: string;
  /**
   * Part added date
   * @type {Date}
   * @memberof Part
   */
  dateAdded?: Date;
  /**
   * The quantity of the part on hand
   * @type {number}
   * @memberof Part
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof Part
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof Part
   */
  notes: string;
  /**
   *
   * @type {string}
   * @memberof Part
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof Part
   */

  readonly customerId?: string;
  /**
   *
   * @type {Date}
   * @memberof Part
   */
  readonly created?: Date;
}

export function PartFromJSON(json: any): Part {
  return PartFromJSONTyped(json, false);
}

export function PartFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Part {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    dateAdded:
      !exists(json, "date_added") || !json["date_added"]
        ? undefined
        : new Date(json["date_added"]),
    quantity: parseInt(json["quantity"]),
    location: json["location"],
    notes: json["notes"],
    vesselId: json["vessel_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function PartToJSON(value?: Part | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    date_added:
      value.dateAdded === undefined ? undefined : value.dateAdded.toISOString(),
    quantity: value.quantity,
    location: value.location,
    notes: !value.notes ? "" : value.notes,
    vessel_id: value.vesselId,
  };
}
