/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  role: UserUpdateRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  fullname?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  password?: string;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
  return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    role: json['role'],
    fullname: !exists(json, 'fullname') ? undefined : json['fullname'],
    password: !exists(json, 'password') ? undefined : json['password'],
  };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    role: value.role,
    fullname: value.fullname,
    password: value.password,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum UserUpdateRoleEnum {
  Root = 'root',
  Admin = 'admin',
  Readonly = 'readonly',
}
