/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Vessel
 */
export interface Vessel {
  /**
   * Vessel unique identifier.
   * @type {string}
   * @memberof Vessel
   */
  readonly id?: string;
  /**
   * The name of the vessel
   * @type {string}
   * @memberof Vessel
   */
  name: string;
  /**
   * The callsign for the vessel
   * @type {string}
   * @memberof Vessel
   */
  callsign?: string;
  /**
   * The port of registration for the vessel
   * @type {string}
   * @memberof Vessel
   */
  registrationPort?: string;
  /**
   * The registration number or mark of the vessel
   * @type {string}
   * @memberof Vessel
   */
  registrationNumber?: string;
  /**
   * Thumbnail encoded in base64
   * @type {string}
   * @memberof Vessel
   */
  thumbnail?: string;
  /**
   *
   * @type {Date}
   * @memberof Vessel
   */
  readonly created?: Date;
  /**
   *
   * @type {string}
   * @memberof Vessel
   */
  readonly customerId?: string;
}

export function VesselFromJSON(json: any): Vessel {
  return VesselFromJSONTyped(json, false);
}

export function VesselFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Vessel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    callsign: !exists(json, "callsign") ? undefined : json["callsign"],
    registrationPort: !exists(json, "registration_port")
      ? undefined
      : json["registration_port"],
    registrationNumber: !exists(json, "registration_number")
      ? undefined
      : json["registration_number"],
    thumbnail: !exists(json, "thumbnail") ? undefined : json["thumbnail"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
  };
}

export function VesselToJSON(value?: Vessel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    callsign: value.callsign,
    registration_port: value.registrationPort,
    registration_number: value.registrationNumber,
    thumbnail: value.thumbnail,
    customer_id: value.customerId,
  };
}
