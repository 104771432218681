/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   * Customer unique identifier
   * @type {string}
   * @memberof Customer
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  licensedvessels?: number;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  deployedVessels?: number;
  /**
   * Creation time
   * @type {Date}
   * @memberof Customer
   */

  /**
   * Parent Customer ID
   * @type {string}
   * @memberof Customer
   */
  readonly parentId?: string;

  readonly created?: Date;
}

export function CustomerFromJSON(json: any): Customer {
  return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Customer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    parentId: !exists(json, "parent_id") ? undefined : json["parent_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    licensedvessels: !exists(json, "licensedvessels")
      ? undefined
      : json["licensedvessels"],
    deployedVessels: !exists(json, "deployed_vessels")
      ? undefined
      : json["deployed_vessels"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function CustomerToJSON(value?: Customer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    parent_id: value.parentId,
    name: value.name,
    licensedvessels:
      value.licensedvessels == undefined
        ? undefined
        : parseInt(value.licensedvessels),
    // deployed_vessels: value.deployedVessels === undefined ? undefined : parseInt(value.deployedVessels),
  };
}
