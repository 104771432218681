import { getCurrentUser } from "../users/selectors";
import { routeEvent, routeIds } from "../routes/events";
import { fx, regEventFx } from "../store";
import * as R from "ramda";
import { getForms } from "../forms/selectors";
import { regFormSubmitHandler } from "../forms/handlers";
import { setSubmittingFx, validateForm } from "../forms/helpers";
import { formEvt } from "../forms/events";
import { MeetingFromJSONTyped, MeetingToJSON } from "../../gen/ts";

const validations: Array<{ name: string; rule: string }> = [
  { name: "meeting_date", rule: "required|date" },
];

const filterPayload = (payload: object): object => {
  if (!payload["sent_to_crew"]) {
    payload = R.assoc("date_sent", null, payload);
  }
  return payload;
};

regEventFx(routeIds.MEETING_LIST, ({ db }) => {
  return [
    fx.api(["customer", "get", "many"], { perPage: 1000 }),
    fx.api(["meeting", "get", "many"], { perPage: 1000 }),
  ];
});

regEventFx(routeIds.MEETING_ADD, (_) => {
  return [fx.db(R.assocPath(["forms", "meeting"], {}))];
});

regEventFx(routeIds.MEETING_DETAIL, ({ db }, { id }) => {
  return [
    fx.api(["customer", "get", "many"], { perPage: 1000 }),
    fx.api(["meeting", "get", "many"], { perPage: 1000 }),
    fx.db(
      R.assocPath(
        ["forms", "meeting"],
        MeetingToJSON(R.path(["data", "meeting", id], db))
      )
    ),
  ];
});

regEventFx(routeIds.MEETING_EDIT, ({ db }, { id }) => {
  return [
    fx.api(["customer", "get", "many"], { perPage: 1000 }),
    fx.api(["meeting", "get", "many"], { perPage: 1000 }),
    fx.db(
      R.assocPath(
        ["forms", "meeting"],
        MeetingToJSON(R.path(["data", "meeting", id], db))
      )
    ),
  ];
});

regFormSubmitHandler(
  ["meeting", "create"],
  ({ db, form }: { db: any; form: Object }) => {
    const validateResult = validateForm(form, validations);
    if (validateResult === true) {
      const payload = MeetingFromJSONTyped(filterPayload(form), true);
      return [
        setSubmittingFx(true),
        fx.api(["meeting", "create", "one"], { payload }),
      ];
    } else {
      return [fx.dispatch(formEvt.SET_VALIDATION, { result: validateResult })];
    }
  }
);

regFormSubmitHandler(
  ["meeting", "edit"],
  ({ db, form }: { db: any; form: Object }) => {
    const validateResult = validateForm(form, validations);
    if (validateResult === true) {
      const payload = MeetingFromJSONTyped(filterPayload(form), true);
      return [
        setSubmittingFx(true),
        fx.api(["meeting", "update", "one"], { id: form["id"], payload }),
      ];
    } else {
      return [fx.dispatch(formEvt.SET_VALIDATION, { result: validateResult })];
    }
  }
);

regFormSubmitHandler(["meeting", "delete"], ({ db, form }) => {
  return [
    setSubmittingFx(true),
    fx.api(["meeting", "delete", "one"], { id: form.id }),
  ];
});

regEventFx("api/create-one-meeting-success", ({ db }, { id }) => {
  return [setSubmittingFx(false), fx.route(routeIds.MEETING_LIST, {})];
});

regEventFx("api/update-one-meeting-success", ({ db }, { id }) => {
  return [setSubmittingFx(false), fx.route(routeIds.MEETING_DETAIL, { id })];
});

regEventFx("api/delete-one-meeting-success", ({ db }, {}) => {
  const form = getForms(db)["meeting"];
  return [
    setSubmittingFx(false),
    fx.db(R.dissocPath(["forms", "meeting"])),
    fx.db(R.dissocPath(["data", "meeting", form.id])),
    fx.dispatch(routeEvent.NAV_TO, [routeIds.MEETING_LIST]),
  ];
});

regEventFx("api/update-one-meeting-failure", () => {
  return [setSubmittingFx(false)];
});
regEventFx("api/create-one-meeting-failure", () => {
  return [setSubmittingFx(false)];
});
regEventFx("api/delete-one-meeting-failure", () => {
  return [setSubmittingFx(false)];
});
