/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Maintenance
 */
export interface Maintenance {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof Maintenance
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  tag: string;
  /**
   * Due date
   * @type {Date}
   * @memberof Maintenance
   */
  dueDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  completedDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  reportedDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  reminderFrq: string;
  /**
   *
   * @type {boolean}
   * @memberof Maintenance
   */
  maintenanceComplete: boolean;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  notes: string;
  /**
   *
   * @type {boolean}
   * @memberof Maintenance
   */
  readonly overdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */
  readonly customerId?: string;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */

  readonly priority?: string;
  /**
   *
   * @type {string}
   * @memberof Maintenance
   */

  readonly signOffUser?: string;
  /**
   *
   * @type {Date}
   * @memberof Maintenance
   */
  readonly created?: Date;
}

export function MaintenanceFromJSON(json: any): Maintenance {
  return MaintenanceFromJSONTyped(json, false);
}

export function MaintenanceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Maintenance {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    tag: json["tag"],
    dueDate:
      !exists(json, "due_date") || !json["due_date"]
        ? undefined
        : new Date(json["due_date"]),
    completedDate:
      !exists(json, "completed_date") || !json["completed_date"]
        ? undefined
        : new Date(json["completed_date"]),
    reportedDate:
      !exists(json, "reported_date") || !json["reported_date"]
        ? undefined
        : new Date(json["reported_date"]),
    reminderFrq: json["reminder_frq"],
    maintenanceComplete: !exists(json, "maintenance_complete")
      ? false
      : json["maintenance_complete"],
    notes: json["notes"],
    overdue: !exists(json, "overdue") ? undefined : json["overdue"],
    vesselId: json["vessel_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    signOffUser: !exists(json, "sign_off_user")
      ? undefined
      : json["sign_off_user"],
    priority: !exists(json, "priority") ? undefined : json["priority"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function MaintenanceToJSON(value?: Maintenance | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  return {
    name: value.name,
    tag: value.tag,
    due_date: !value.dueDate
      ? ""
      : value.dueDate.toString() == "Invalid Date"
      ? ""
      : value.dueDate.toISOString(),
    completed_date: !value.completedDate
      ? ""
      : value.completedDate.toString() == "Invalid Date"
      ? ""
      : value.completedDate.toISOString(),
    reported_date: !value.reportedDate
      ? ""
      : value.reportedDate.toString() == "Invalid Date"
      ? ""
      : value.reportedDate.toISOString(),
    reminder_frq: value.reminderFrq,
    maintenance_complete: value.maintenanceComplete,
    priority: value.priority,
    sign_off_user: !value.signOffUser ? undefined : value.signOffUser,
    notes: !value.notes ? "" : value.notes,
    vessel_id: value.vesselId,
  };
}
