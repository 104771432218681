import React from "react";
import { theme } from "styles/theme";
import { component, createSub } from "framework-x";
import { Flex, Text, Button, Box } from "../../components/standalone";
import { getCrewStatuses, getSelectedCrewId } from "../selectors";
import * as R from "ramda";
import { getSelectedVesselId } from "../selectors";
import { routeIds } from "routes/events";
import { getRouteId } from "routes/selectors";

export default component(
  "CrewStatusAlert",
  createSub({
    crewStatuses: getCrewStatuses,
    crewId: getSelectedCrewId,
    routeId: getRouteId,
  }),
  ({ crewStatuses, routeId, crewId }) => {
    if (!crewStatuses) {
      return null;
    }
    let overdueCount = 0;
    let upcomingCount = 0;
    if (routeId == routeIds.CREW_LIST) {
      overdueCount = crewStatuses == {} ? 0 : crewStatuses.totalOverdueCount;
      upcomingCount = crewStatuses == {} ? 0 : crewStatuses.totalUpcomingCount;
    } else if (routeId == routeIds.CREW_DETAIL) {
      overdueCount = R.pathOr(
        0,
        ["crewMembers", crewId, "overdueCount"],
        crewStatuses
      );
      upcomingCount = R.pathOr(
        0,
        ["crewMembers", crewId, "upcomingCount"],
        crewStatuses
      );
    }

    if (overdueCount == 0 && upcomingCount == 0) return null;
    return (
      <Flex alignItems="center">
        <Flex alignItems={"center"} direction={"column"}>
          <Text fontSize="12px">&nbsp;</Text>
          <Text fontSize="13px" color={theme.colors.gray[500]}>
            Alerts:{" "}
          </Text>
        </Flex>
        {overdueCount > 0 && (
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"} direction={"column"}>
              <Text fontSize="12px">&nbsp;</Text>
              <Flex alignItems={"center"}>
                <Text
                  fontSize="13px"
                  fontWeight={500}
                  color={theme.colors.red[500]}
                  marginLeft={2}
                >
                  Overdue
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
              <Box
                fontSize="12px"
                fontWeight={500}
                color={theme.colors.red[500]}
              >
                Crew
              </Box>
              <Button
                size="sm"
                fontSize="13px"
                color={theme.colors.red[500]}
                backgroundColor={`${theme.colors.red[100]} !important`}
                border={`solid 1px ${theme.colors.red[300]}`}
                boxShadow={"none !important"}
                cursor={"default"}
                marginLeft={0}
                p={2}
              >
                {overdueCount}
              </Button>
            </Flex>
          </Flex>
        )}
        {upcomingCount > 0 && (
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"} direction={"column"}>
              <Text fontSize="12px">&nbsp;</Text>
              <Flex alignItems={"center"}>
                <Text
                  fontSize="13px"
                  fontWeight={500}
                  color={theme.colors.orange[500]}
                  marginLeft={2}
                >
                  Upcoming
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
              <Box
                fontSize="12px"
                fontWeight={500}
                color={theme.colors.orange[500]}
              >
                Crew
              </Box>
              <Button
                size="sm"
                fontSize="13px"
                color={theme.colors.orange[500]}
                backgroundColor={`${theme.colors.orange[100]} !important`}
                border={`solid 1px ${theme.colors.orange[300]}`}
                boxShadow={"none !important"}
                cursor={"default"}
                marginLeft={0}
                p={2}
              >
                {upcomingCount}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  }
);
