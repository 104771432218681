/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserSetting
 */
export interface UserSetting {
  /**
   * UserSetting unique identifier.
   * @type {string}
   * @memberof UserSetting
   */
  readonly id: string;
  /**
   * UserSetting unique identifier.
   * @type {string}
   * @memberof UserSetting
   */
  readonly userId: string;
  /**
   * UserSetting unique identifier.
   * @type {string}
   * @memberof UserSetting
   */
  readonly customerId: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSetting
   */
  sendOverdueReminders: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSetting
   */
  sendUpcomingReminders: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSetting
   */
  overdueReminderFrequency: string;
  /**
   *
   * @type {string}
   * @memberof UserSetting
   */
  upcomingReminderFrequency: string;

  readonly created?: Date;
}

export function UserSettingFromJSON(json: any): UserSetting {
  return UserSettingFromJSONTyped(json, false);
}

export function UserSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    userId: json["user_id"],
    customerId: json["customer_id"],
    sendOverdueReminders: json["send_overdue_reminders"],
    sendUpcomingReminders: json["send_upcoming_reminders"],
    overdueReminderFrequency: json["overdue_reminder_frequency"],
    upcomingReminderFrequency: json["upcoming_reminder_frequency"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function UserSettingToJSON(value?: UserSetting | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user_id: value.userId,
    customer_id: value.customerId,
    send_overdue_reminders: value.sendOverdueReminders,
    send_upcoming_reminders: value.sendUpcomingReminders,
    overdue_reminder_frequency: value.overdueReminderFrequency,
    upcoming_reminder_frequency: value.upcomingReminderFrequency,
  };
}
