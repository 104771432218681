/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { CrewOverdue, CrewOverdueFromJSONTyped } from "../models";

export interface GetCrewOverdueRequest {}

export class CrewOverdueApi extends runtime.BaseAPI {
  /**
   * Get CrewOverdue Information
   */
  async getCrewOverdueRaw(
    requestParameters: GetCrewOverdueRequest
  ): Promise<runtime.ApiResponse<CrewOverdue>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/crewoverdue/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CrewOverdueFromJSONTyped(jsonValue)
    );
  }

  /**
   * Get user information
   */
  async getCrewOverdue(
    requestParameters: GetCrewOverdueRequest
  ): Promise<CrewOverdue> {
    const response = await this.getCrewOverdueRaw(requestParameters);
    return await response.value();
  }
}
