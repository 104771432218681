/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CrewCertificate
 */
export interface CrewCertificate {
  /**
   * Certificate unique identifier.
   * @type {string}
   * @memberof CrewCertificate
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof CrewCertificate
   */
  qualification: string;
  /**
   *
   * @type {string}
   * @memberof CrewCertificate
   */
  certNumber?: string;
  /**
   * The date the certificate was issued
   * @type {Date}
   * @memberof CrewCertificate
   */
  issueDate?: Date;
  /**
   * Expiry date
   * @type {Date}
   * @memberof CrewCertificate
   */
  expiryDate?: Date;
  /**
   * Add any notes regarding the certificate document
   * @type {string}
   * @memberof CrewCertificate
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrewCertificate
   */
  readonly overdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrewCertificate
   */
  crewId: string;
  /**
   *
   * @type {string}
   * @memberof CrewCertificate
   */
  readonly customerId?: string;
  /**
   *
   * @type {Date}
   * @memberof CrewCertificate
   */
  readonly created?: Date;
}

export function CrewCertificateFromJSON(json: any): CrewCertificate {
  return CrewCertificateFromJSONTyped(json, false);
}

export function CrewCertificateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CrewCertificate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    qualification: json["qualification"],
    certNumber: !exists(json, "cert_number") ? undefined : json["cert_number"],
    issueDate:
      !exists(json, "issue_date") || !json["issue_date"]
        ? undefined
        : new Date(json["issue_date"]),
    expiryDate:
      !exists(json, "expiry_date") || !json["expiry_date"]
        ? undefined
        : new Date(json["expiry_date"]),
    notes: !exists(json, "notes") ? undefined : json["notes"],
    overdue: !exists(json, "overdue") ? undefined : json["overdue"],
    crewId: json["crew_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function CrewCertificateToJSON(value?: CrewCertificate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    qualification: value.qualification,
    cert_number: value.certNumber,
    issue_date: !value.issueDate ? "" : value.issueDate.toISOString(),
    expiry_date: !value.expiryDate ? "" : value.expiryDate.toISOString(),
    notes: value.notes,
    crew_id: value.crewId,
  };
}
