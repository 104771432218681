/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Action,
  ActionFromJSON,
  ActionToJSON,
  ActionPagination,
  ActionPaginationFromJSON,
  ActionPaginationToJSON,
  Audit,
  AuditFromJSON,
  AuditToJSON,
  AuditPagination,
  AuditPaginationFromJSON,
  AuditPaginationToJSON,
  Certificate,
  CertificateFromJSON,
  CertificateToJSON,
  CertificatePagination,
  CertificatePaginationFromJSON,
  CertificatePaginationToJSON,
  Drill,
  DrillFromJSON,
  DrillToJSON,
  DrillPagination,
  DrillPaginationFromJSON,
  DrillPaginationToJSON,
  HealthEquipment,
  HealthEquipmentFromJSON,
  HealthEquipmentToJSON,
  HealthEquipmentPagination,
  HealthEquipmentPaginationFromJSON,
  HealthEquipmentPaginationToJSON,
  Maintenance,
  MaintenanceFromJSON,
  MaintenanceToJSON,
  MaintenancePagination,
  MaintenancePaginationFromJSON,
  MaintenancePaginationToJSON,
  Part,
  PartFromJSON,
  PartToJSON,
  PartPagination,
  PartPaginationFromJSON,
  PartPaginationToJSON,
  Review,
  ReviewFromJSON,
  ReviewToJSON,
  ReviewPagination,
  ReviewPaginationFromJSON,
  ReviewPaginationToJSON,
  SafetyEquipment,
  SafetyEquipmentFromJSON,
  SafetyEquipmentToJSON,
  SafetyEquipmentPagination,
  SafetyEquipmentPaginationFromJSON,
  SafetyEquipmentPaginationToJSON,
  Survey,
  SurveyFromJSON,
  SurveyToJSON,
  SurveyPagination,
  SurveyPaginationFromJSON,
  SurveyPaginationToJSON,
  Task,
  TaskFromJSON,
  TaskToJSON,
  TaskPagination,
  TaskPaginationFromJSON,
  TaskPaginationToJSON,
  Training,
  TrainingFromJSON,
  TrainingToJSON,
  TrainingPagination,
  TrainingPaginationFromJSON,
  TrainingPaginationToJSON,
  Vessel,
  VesselFromJSON,
  VesselToJSON,
  VesselPagination,
  VesselPaginationFromJSON,
  VesselPaginationToJSON,
  Repair,
  RepairFromJSON,
  RepairToJSON,
  RepairPagination,
  RepairPaginationFromJSON,
  RepairPaginationToJSON,
} from "../models";

export interface DeleteActionItemRequest {
  id: string;
}

export interface DeleteAuditItemRequest {
  id: string;
}

export interface DeleteCertificateItemRequest {
  id: string;
}

export interface DeleteDrillItemRequest {
  id: string;
}

export interface DeleteEquipmentItemRequest {
  id: string;
}

export interface DeleteMaintenanceItemRequest {
  id: string;
}

export interface DeletePartItemRequest {
  id: string;
}

export interface DeleteReviewItemRequest {
  id: string;
}

export interface DeleteSafetyEquipmentItemRequest {
  id: string;
}

export interface DeleteSurveyItemRequest {
  id: string;
}

export interface DeleteTaskItemRequest {
  id: string;
}

export interface DeleteTrainingItemRequest {
  id: string;
}

export interface DeleteRepairItemRequest {
  id: string;
}

export interface DeleteVesselItemRequest {
  id: string;
  customerId: string;
}

export interface GetActionItemRequest {
  id: string;
}

export interface GetActionListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetAuditItemRequest {
  id: string;
}

export interface GetAuditListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetCertificateItemRequest {
  id: string;
}

export interface GetCertificateListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetCrewListRequest {
  id: string;
  page?: number;
  perPage?: number;
  customerId?: string;
}

export interface GetDrillItemRequest {
  id: string;
}

export interface GetDrillListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetEquipmentItemRequest {
  id: string;
}

export interface GetEquipmentListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetMaintenanceItemRequest {
  id: string;
}

export interface GetMaintenanceListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetPartItemRequest {
  id: string;
}

export interface GetPartListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetRepairItemRequest {
  id: string;
}

export interface GetRepairListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetReviewItemRequest {
  id: string;
}

export interface GetReviewListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetSafetyEquipmentItemRequest {
  id: string;
}

export interface GetSafetyEquipmentListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetSurveyItemRequest {
  id: string;
}

export interface GetSurveyListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetTaskItemRequest {
  id: string;
}

export interface GetTaskListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetTrainingItemRequest {
  id: string;
}

export interface GetTrainingListRequest {
  page?: number;
  perPage?: number;
  overdue?: boolean;
  vesselId?: string;
  customerId?: string;
}

export interface GetVesselItemRequest {
  id: string;
}

export interface GetVesselListRequest {
  page?: number;
  perPage?: number;
  customerId?: string;
}

export interface PostActionListRequest {
  payload: Action;
}

export interface PostAuditListRequest {
  payload: Audit;
}

export interface PostCertificateListRequest {
  payload: Certificate;
}

export interface PostDrillListRequest {
  payload: Drill;
}

export interface PostEquipmentListRequest {
  payload: HealthEquipment;
}

export interface PostMaintenanceListRequest {
  payload: Maintenance;
}

export interface PostPartListRequest {
  payload: Part;
}

export interface PostRepairListRequest {
  payload: Repair;
}

export interface PostReviewListRequest {
  payload: Review;
}

export interface PostSafetyEquipmentListRequest {
  payload: SafetyEquipment;
}

export interface PostSurveyListRequest {
  payload: Survey;
}

export interface PostTaskListRequest {
  payload: Task;
}

export interface PostTrainingListRequest {
  payload: Training;
}

export interface PostVesselListRequest {
  payload: Vessel;
}

export interface PutActionItemRequest {
  id: string;
  payload: Action;
}

export interface PutAuditItemRequest {
  id: string;
  payload: Audit;
}

export interface PutCertificateItemRequest {
  id: string;
  payload: Certificate;
}

export interface PutDrillItemRequest {
  id: string;
  payload: Drill;
}

export interface PutEquipmentItemRequest {
  id: string;
  payload: HealthEquipment;
}

export interface PutMaintenanceItemRequest {
  id: string;
  payload: Maintenance;
}

export interface PutPartItemRequest {
  id: string;
  payload: Part;
}

export interface PutRepairItemRequest {
  id: string;
  payload: Repair;
}

export interface PutReviewItemRequest {
  id: string;
  payload: Review;
}

export interface PutSafetyEquipmentItemRequest {
  id: string;
  payload: SafetyEquipment;
}

export interface PutSurveyItemRequest {
  id: string;
  payload: Survey;
}

export interface PutTaskItemRequest {
  id: string;
  payload: Task;
}

export interface PutTrainingItemRequest {
  id: string;
  payload: Training;
}

export interface PutVesselItemRequest {
  id: string;
  payload: Vessel;
}

/**
 *
 */
export class VesselApi extends runtime.BaseAPI {
  /**
   * All related documents will be removed.
   * Delete action
   */
  async deleteActionItemRaw(
    requestParameters: DeleteActionItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteActionItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/action/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete action
   */
  async deleteActionItem(
    requestParameters: DeleteActionItemRequest
  ): Promise<void> {
    await this.deleteActionItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete audit
   */
  async deleteAuditItemRaw(
    requestParameters: DeleteAuditItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteAuditItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/audit/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete audit
   */
  async deleteAuditItem(
    requestParameters: DeleteAuditItemRequest
  ): Promise<void> {
    await this.deleteAuditItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete certificate
   */
  async deleteCertificateItemRaw(
    requestParameters: DeleteCertificateItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteCertificateItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesselcert/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete certificate
   */
  async deleteCertificateItem(
    requestParameters: DeleteCertificateItemRequest
  ): Promise<void> {
    await this.deleteCertificateItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete drill
   */
  async deleteDrillItemRaw(
    requestParameters: DeleteDrillItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteDrillItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/drill/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete drill
   */
  async deleteDrillItem(
    requestParameters: DeleteDrillItemRequest
  ): Promise<void> {
    await this.deleteDrillItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete equipment
   */
  async deleteEquipmentItemRaw(
    requestParameters: DeleteEquipmentItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/health/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete equipment
   */
  async deleteEquipmentItem(
    requestParameters: DeleteEquipmentItemRequest
  ): Promise<void> {
    await this.deleteEquipmentItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete maintenance
   */
  async deleteMaintenanceItemRaw(
    requestParameters: DeleteMaintenanceItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteMaintenanceItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/maintenance/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete maintenance
   */
  async deleteMaintenanceItem(
    requestParameters: DeleteMaintenanceItemRequest
  ): Promise<void> {
    await this.deleteMaintenanceItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete part
   */
  async deletePartItemRaw(
    requestParameters: DeletePartItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deletePartItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/part/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete part
   */
  async deletePartItem(
    requestParameters: DeletePartItemRequest
  ): Promise<void> {
    await this.deletePartItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete part
   */
  async deleteRepairItemRaw(
    requestParameters: DeleteRepairItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deletePartItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/requests/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete part
   */
  async deleteRepairItem(
    requestParameters: DeleteRepairItemRequest
  ): Promise<void> {
    await this.deleteRepairItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete review
   */
  async deleteReviewItemRaw(
    requestParameters: DeleteReviewItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteReviewItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/review/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete review
   */
  async deleteReviewItem(
    requestParameters: DeleteReviewItemRequest
  ): Promise<void> {
    await this.deleteReviewItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete healthandsafety
   */
  async deleteSafetyEquipmentItemRaw(
    requestParameters: DeleteSafetyEquipmentItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSafetyEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/safetyequipment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete healthandsafety
   */
  async deleteSafetyEquipmentItem(
    requestParameters: DeleteSafetyEquipmentItemRequest
  ): Promise<void> {
    await this.deleteSafetyEquipmentItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete survey
   */
  async deleteSurveyItemRaw(
    requestParameters: DeleteSurveyItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSurveyItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/survey/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete survey
   */
  async deleteSurveyItem(
    requestParameters: DeleteSurveyItemRequest
  ): Promise<void> {
    await this.deleteSurveyItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete task
   */
  async deleteTaskItemRaw(
    requestParameters: DeleteTaskItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTaskItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/task/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete task
   */
  async deleteTaskItem(
    requestParameters: DeleteTaskItemRequest
  ): Promise<void> {
    await this.deleteTaskItemRaw(requestParameters);
  }

  /**
   * All related documents will be removed.
   * Delete training
   */
  async deleteTrainingItemRaw(
    requestParameters: DeleteTrainingItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTrainingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/training/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related documents will be removed.
   * Delete training
   */
  async deleteTrainingItem(
    requestParameters: DeleteTrainingItemRequest
  ): Promise<void> {
    await this.deleteTrainingItemRaw(requestParameters);
  }

  /**
   * All related Tasks will be removed.
   * Delete vessel
   */
  async deleteVesselItemRaw(
    requestParameters: DeleteVesselItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteVesselItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/${encodeURIComponent(
        String(requestParameters.id)
      )}?customer_id=${requestParameters.customerId}`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * All related Tasks will be removed.
   * Delete vessel
   */
  async deleteVesselItem(
    requestParameters: DeleteVesselItemRequest
  ): Promise<void> {
    await this.deleteVesselItemRaw(requestParameters);
  }

  /**
   * Get Action information
   */
  async getActionItemRaw(
    requestParameters: GetActionItemRequest
  ): Promise<runtime.ApiResponse<Action>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getActionItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/action/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActionFromJSON(jsonValue)
    );
  }

  /**
   * Get Action information
   */
  async getActionItem(
    requestParameters: GetActionItemRequest
  ): Promise<Action> {
    const response = await this.getActionItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Action list
   */
  async getActionListRaw(
    requestParameters: GetActionListRequest
  ): Promise<runtime.ApiResponse<Array<ActionPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/action/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ActionPaginationFromJSON)
    );
  }

  /**
   * Get Action list
   */
  async getActionList(
    requestParameters: GetActionListRequest
  ): Promise<Array<ActionPagination>> {
    const response = await this.getActionListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Audit information
   */
  async getAuditItemRaw(
    requestParameters: GetAuditItemRequest
  ): Promise<runtime.ApiResponse<Audit>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getAuditItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/audit/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuditFromJSON(jsonValue)
    );
  }

  /**
   * Get Audit information
   */
  async getAuditItem(requestParameters: GetAuditItemRequest): Promise<Audit> {
    const response = await this.getAuditItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Audit list
   */
  async getAuditListRaw(
    requestParameters: GetAuditListRequest
  ): Promise<runtime.ApiResponse<Array<AuditPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/audit/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AuditPaginationFromJSON)
    );
  }

  /**
   * Get Audit list
   */
  async getAuditList(
    requestParameters: GetAuditListRequest
  ): Promise<Array<AuditPagination>> {
    const response = await this.getAuditListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Certificate information
   */
  async getCertificateItemRaw(
    requestParameters: GetCertificateItemRequest
  ): Promise<runtime.ApiResponse<Certificate>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCertificateItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesselcert/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CertificateFromJSON(jsonValue)
    );
  }

  /**
   * Get Certificate information
   */
  async getCertificateItem(
    requestParameters: GetCertificateItemRequest
  ): Promise<Certificate> {
    const response = await this.getCertificateItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Certificate list
   */
  async getCertificateListRaw(
    requestParameters: GetCertificateListRequest
  ): Promise<runtime.ApiResponse<Array<CertificatePagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesselcert/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CertificatePaginationFromJSON)
    );
  }

  /**
   * Get Certificate list
   */
  async getCertificateList(
    requestParameters: GetCertificateListRequest
  ): Promise<Array<CertificatePagination>> {
    const response = await this.getCertificateListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get crew members for a given vessel
   */
  async getCrewListRaw(
    requestParameters: GetCrewListRequest
  ): Promise<runtime.ApiResponse<VesselPagination>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCrewList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/crew/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VesselPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get crew members for a given vessel
   */
  async getCrewList(
    requestParameters: GetCrewListRequest
  ): Promise<VesselPagination> {
    const response = await this.getCrewListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Drill information
   */
  async getDrillItemRaw(
    requestParameters: GetDrillItemRequest
  ): Promise<runtime.ApiResponse<Drill>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getDrillItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/drill/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DrillFromJSON(jsonValue)
    );
  }

  /**
   * Get Drill information
   */
  async getDrillItem(requestParameters: GetDrillItemRequest): Promise<Drill> {
    const response = await this.getDrillItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Drill list
   */
  async getDrillListRaw(
    requestParameters: GetDrillListRequest
  ): Promise<runtime.ApiResponse<Array<DrillPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/drill/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(DrillPaginationFromJSON)
    );
  }

  /**
   * Get Drill list
   */
  async getDrillList(
    requestParameters: GetDrillListRequest
  ): Promise<Array<DrillPagination>> {
    const response = await this.getDrillListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Equipment information
   */
  async getEquipmentItemRaw(
    requestParameters: GetEquipmentItemRequest
  ): Promise<runtime.ApiResponse<HealthEquipment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/health/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Get Equipment information
   */
  async getEquipmentItem(
    requestParameters: GetEquipmentItemRequest
  ): Promise<HealthEquipment> {
    const response = await this.getEquipmentItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Equipment list
   */
  async getEquipmentListRaw(
    requestParameters: GetEquipmentListRequest
  ): Promise<runtime.ApiResponse<Array<HealthEquipmentPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/health/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(HealthEquipmentPaginationFromJSON)
    );
  }

  /**
   * Get Equipment list
   */
  async getEquipmentList(
    requestParameters: GetEquipmentListRequest
  ): Promise<Array<HealthEquipmentPagination>> {
    const response = await this.getEquipmentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Maintenance information
   */
  async getMaintenanceItemRaw(
    requestParameters: GetMaintenanceItemRequest
  ): Promise<runtime.ApiResponse<Maintenance>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getMaintenanceItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/maintenance/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceFromJSON(jsonValue)
    );
  }

  /**
   * Get Maintenance information
   */
  async getMaintenanceItem(
    requestParameters: GetMaintenanceItemRequest
  ): Promise<Maintenance> {
    const response = await this.getMaintenanceItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Maintenance list
   */
  async getMaintenanceListRaw(
    requestParameters: GetMaintenanceListRequest
  ): Promise<runtime.ApiResponse<Array<MaintenancePagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/maintenance/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(MaintenancePaginationFromJSON)
    );
  }

  /**
   * Get Maintenance list
   */
  async getMaintenanceList(
    requestParameters: GetMaintenanceListRequest
  ): Promise<Array<MaintenancePagination>> {
    const response = await this.getMaintenanceListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Part information
   */
  async getPartItemRaw(
    requestParameters: GetPartItemRequest
  ): Promise<runtime.ApiResponse<Part>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getPartItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/part/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PartFromJSON(jsonValue)
    );
  }

  /**
   * Get Part information
   */
  async getPartItem(requestParameters: GetPartItemRequest): Promise<Part> {
    const response = await this.getPartItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Part list
   */
  async getPartListRaw(
    requestParameters: GetPartListRequest
  ): Promise<runtime.ApiResponse<Array<PartPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/part/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PartPaginationFromJSON)
    );
  }

  /**
   * Get Part list
   */
  async getPartList(
    requestParameters: GetPartListRequest
  ): Promise<Array<PartPagination>> {
    const response = await this.getPartListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Repair information
   */
  async getRepairItemRaw(
    requestParameters: GetRepairItemRequest
  ): Promise<runtime.ApiResponse<Repair>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRepairItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/requests/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RepairFromJSON(jsonValue)
    );
  }

  /**
   * Get Repair information
   */
  async getRepairItem(
    requestParameters: GetRepairItemRequest
  ): Promise<Repair> {
    const response = await this.getRepairItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Repair list
   */
  async getRepairListRaw(
    requestParameters: GetRepairListRequest
  ): Promise<runtime.ApiResponse<Array<RepairPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/requests/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(RepairPaginationFromJSON)
    );
  }

  /**
   * Get Repair list
   */
  async getRepairList(
    requestParameters: GetRepairListRequest
  ): Promise<Array<RepairPagination>> {
    const response = await this.getRepairListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Review information
   */
  async getReviewItemRaw(
    requestParameters: GetReviewItemRequest
  ): Promise<runtime.ApiResponse<Review>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getReviewItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/review/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReviewFromJSON(jsonValue)
    );
  }

  /**
   * Get Review information
   */
  async getReviewItem(
    requestParameters: GetReviewItemRequest
  ): Promise<Review> {
    const response = await this.getReviewItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Review list
   */
  async getReviewListRaw(
    requestParameters: GetReviewListRequest
  ): Promise<runtime.ApiResponse<Array<ReviewPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/review/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ReviewPaginationFromJSON)
    );
  }

  /**
   * Get Review list
   */
  async getReviewList(
    requestParameters: GetReviewListRequest
  ): Promise<Array<ReviewPagination>> {
    const response = await this.getReviewListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get HealthandSafety information
   */
  async getSafetyEquipmentItemRaw(
    requestParameters: GetSafetyEquipmentItemRequest
  ): Promise<runtime.ApiResponse<SafetyEquipment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getSafetyEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/safetyequipment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SafetyEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Get HealthandSafety information
   */
  async getSafetyEquipmentItem(
    requestParameters: GetSafetyEquipmentItemRequest
  ): Promise<SafetyEquipment> {
    const response = await this.getSafetyEquipmentItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get HealthandSafety list
   */
  async getSafetyEquipmentListRaw(
    requestParameters: GetSafetyEquipmentListRequest
  ): Promise<runtime.ApiResponse<Array<SafetyEquipmentPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/safetyequipment/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SafetyEquipmentPaginationFromJSON)
    );
  }

  /**
   * Get HealthandSafety list
   */
  async getSafetyEquipmentList(
    requestParameters: GetSafetyEquipmentListRequest
  ): Promise<Array<SafetyEquipmentPagination>> {
    const response = await this.getSafetyEquipmentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Survey information
   */
  async getSurveyItemRaw(
    requestParameters: GetSurveyItemRequest
  ): Promise<runtime.ApiResponse<Survey>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getSurveyItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/survey/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SurveyFromJSON(jsonValue)
    );
  }

  /**
   * Get Survey information
   */
  async getSurveyItem(
    requestParameters: GetSurveyItemRequest
  ): Promise<Survey> {
    const response = await this.getSurveyItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Survey list
   */
  async getSurveyListRaw(
    requestParameters: GetSurveyListRequest
  ): Promise<runtime.ApiResponse<Array<SurveyPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/survey/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SurveyPaginationFromJSON)
    );
  }

  /**
   * Get Survey list
   */
  async getSurveyList(
    requestParameters: GetSurveyListRequest
  ): Promise<Array<SurveyPagination>> {
    const response = await this.getSurveyListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get task information
   */
  async getTaskItemRaw(
    requestParameters: GetTaskItemRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTaskItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/task/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Get task information
   */
  async getTaskItem(requestParameters: GetTaskItemRequest): Promise<Task> {
    const response = await this.getTaskItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get task list
   */
  async getTaskListRaw(
    requestParameters: GetTaskListRequest
  ): Promise<runtime.ApiResponse<Array<TaskPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/task/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TaskPaginationFromJSON)
    );
  }

  /**
   * Get task list
   */
  async getTaskList(
    requestParameters: GetTaskListRequest
  ): Promise<Array<TaskPagination>> {
    const response = await this.getTaskListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Training information
   */
  async getTrainingItemRaw(
    requestParameters: GetTrainingItemRequest
  ): Promise<runtime.ApiResponse<Training>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTrainingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/training/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TrainingFromJSON(jsonValue)
    );
  }

  /**
   * Get Training information
   */
  async getTrainingItem(
    requestParameters: GetTrainingItemRequest
  ): Promise<Training> {
    const response = await this.getTrainingItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Training list
   */
  async getTrainingListRaw(
    requestParameters: GetTrainingListRequest
  ): Promise<runtime.ApiResponse<Array<TrainingPagination>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.overdue !== undefined) {
      queryParameters["overdue"] = requestParameters.overdue;
    }

    if (requestParameters.vesselId !== undefined) {
      queryParameters["vessel_id"] = requestParameters.vesselId;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/training/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TrainingPaginationFromJSON)
    );
  }

  /**
   * Get Training list
   */
  async getTrainingList(
    requestParameters: GetTrainingListRequest
  ): Promise<Array<TrainingPagination>> {
    const response = await this.getTrainingListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vessel
   */
  async getVesselItemRaw(
    requestParameters: GetVesselItemRequest
  ): Promise<runtime.ApiResponse<Vessel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getVesselItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VesselFromJSON(jsonValue)
    );
  }

  /**
   * Get vessel
   */
  async getVesselItem(
    requestParameters: GetVesselItemRequest
  ): Promise<Vessel> {
    const response = await this.getVesselItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get vessel list
   */
  async getVesselListRaw(
    requestParameters: GetVesselListRequest
  ): Promise<runtime.ApiResponse<VesselPagination>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VesselPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get vessel list
   */
  async getVesselList(
    requestParameters: GetVesselListRequest
  ): Promise<VesselPagination> {
    const response = await this.getVesselListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Action
   */
  async postActionListRaw(
    requestParameters: PostActionListRequest
  ): Promise<runtime.ApiResponse<Action>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postActionList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/action/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ActionToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActionFromJSON(jsonValue)
    );
  }

  /**
   * Create new Action
   */
  async postActionList(
    requestParameters: PostActionListRequest
  ): Promise<Action> {
    const response = await this.postActionListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Audit
   */
  async postAuditListRaw(
    requestParameters: PostAuditListRequest
  ): Promise<runtime.ApiResponse<Audit>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postAuditList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/audit/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AuditToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuditFromJSON(jsonValue)
    );
  }

  /**
   * Create new Audit
   */
  async postAuditList(requestParameters: PostAuditListRequest): Promise<Audit> {
    const response = await this.postAuditListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Certificate
   */
  async postCertificateListRaw(
    requestParameters: PostCertificateListRequest
  ): Promise<runtime.ApiResponse<Certificate>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postCertificateList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesselcert/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CertificateToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CertificateFromJSON(jsonValue)
    );
  }

  /**
   * Create new Certificate
   */
  async postCertificateList(
    requestParameters: PostCertificateListRequest
  ): Promise<Certificate> {
    const response = await this.postCertificateListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Drill
   */
  async postDrillListRaw(
    requestParameters: PostDrillListRequest
  ): Promise<runtime.ApiResponse<Drill>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postDrillList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/drill/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DrillToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DrillFromJSON(jsonValue)
    );
  }

  /**
   * Create new Drill
   */
  async postDrillList(requestParameters: PostDrillListRequest): Promise<Drill> {
    const response = await this.postDrillListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Equipment
   */
  async postEquipmentListRaw(
    requestParameters: PostEquipmentListRequest
  ): Promise<runtime.ApiResponse<HealthEquipment>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postEquipmentList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/health/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: HealthEquipmentToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Create new Equipment
   */
  async postEquipmentList(
    requestParameters: PostEquipmentListRequest
  ): Promise<HealthEquipment> {
    const response = await this.postEquipmentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Maintenance
   */
  async postMaintenanceListRaw(
    requestParameters: PostMaintenanceListRequest
  ): Promise<runtime.ApiResponse<Maintenance>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postMaintenanceList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/maintenance/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MaintenanceToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceFromJSON(jsonValue)
    );
  }

  /**
   * Create new Maintenance
   */
  async postMaintenanceList(
    requestParameters: PostMaintenanceListRequest
  ): Promise<Maintenance> {
    const response = await this.postMaintenanceListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Part
   */
  async postPartListRaw(
    requestParameters: PostPartListRequest
  ): Promise<runtime.ApiResponse<Part>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postPartList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/part/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PartToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PartFromJSON(jsonValue)
    );
  }

  /**
   * Create new Part
   */
  async postPartList(requestParameters: PostPartListRequest): Promise<Part> {
    const response = await this.postPartListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Repair
   */
  async postRepairListRaw(
    requestParameters: PostRepairListRequest
  ): Promise<runtime.ApiResponse<Repair>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postRepairList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/requests/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RepairToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RepairFromJSON(jsonValue)
    );
  }

  /**
   * Create new Repair
   */
  async postRepairList(
    requestParameters: PostRepairListRequest
  ): Promise<Repair> {
    const response = await this.postRepairListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Review
   */
  async postReviewListRaw(
    requestParameters: PostReviewListRequest
  ): Promise<runtime.ApiResponse<Review>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postReviewList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/review/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ReviewToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReviewFromJSON(jsonValue)
    );
  }

  /**
   * Create new Review
   */
  async postReviewList(
    requestParameters: PostReviewListRequest
  ): Promise<Review> {
    const response = await this.postReviewListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new HealthandSafety
   */
  async postSafetyEquipmentListRaw(
    requestParameters: PostSafetyEquipmentListRequest
  ): Promise<runtime.ApiResponse<SafetyEquipment>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postSafetyEquipmentList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/safetyequipment/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SafetyEquipmentToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SafetyEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Create new HealthandSafety
   */
  async postSafetyEquipmentList(
    requestParameters: PostSafetyEquipmentListRequest
  ): Promise<SafetyEquipment> {
    const response = await this.postSafetyEquipmentListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Survey
   */
  async postSurveyListRaw(
    requestParameters: PostSurveyListRequest
  ): Promise<runtime.ApiResponse<Survey>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postSurveyList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/survey/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SurveyToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SurveyFromJSON(jsonValue)
    );
  }

  /**
   * Create new Survey
   */
  async postSurveyList(
    requestParameters: PostSurveyListRequest
  ): Promise<Survey> {
    const response = await this.postSurveyListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new task
   */
  async postTaskListRaw(
    requestParameters: PostTaskListRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postTaskList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/task/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TaskToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Create new task
   */
  async postTaskList(requestParameters: PostTaskListRequest): Promise<Task> {
    const response = await this.postTaskListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Training
   */
  async postTrainingListRaw(
    requestParameters: PostTrainingListRequest
  ): Promise<runtime.ApiResponse<Training>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postTrainingList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/training/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TrainingToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TrainingFromJSON(jsonValue)
    );
  }

  /**
   * Create new Training
   */
  async postTrainingList(
    requestParameters: PostTrainingListRequest
  ): Promise<Training> {
    const response = await this.postTrainingListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new vessel
   */
  async postVesselListRaw(
    requestParameters: PostVesselListRequest
  ): Promise<runtime.ApiResponse<Vessel>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postVesselList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/?customer_id=${requestParameters.payload.customerId}`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: VesselToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VesselFromJSON(jsonValue)
    );
  }

  /**
   * Create new vessel
   */
  async postVesselList(
    requestParameters: PostVesselListRequest
  ): Promise<Vessel> {
    const response = await this.postVesselListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update action
   */
  async putActionItemRaw(
    requestParameters: PutActionItemRequest
  ): Promise<runtime.ApiResponse<Action>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putActionItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putActionItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/action/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ActionToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActionFromJSON(jsonValue)
    );
  }

  /**
   * Update action
   */
  async putActionItem(
    requestParameters: PutActionItemRequest
  ): Promise<Action> {
    const response = await this.putActionItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update audit
   */
  async putAuditItemRaw(
    requestParameters: PutAuditItemRequest
  ): Promise<runtime.ApiResponse<Audit>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putAuditItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putAuditItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/audit/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: AuditToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuditFromJSON(jsonValue)
    );
  }

  /**
   * Update audit
   */
  async putAuditItem(requestParameters: PutAuditItemRequest): Promise<Audit> {
    const response = await this.putAuditItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update certificate
   */
  async putCertificateItemRaw(
    requestParameters: PutCertificateItemRequest
  ): Promise<runtime.ApiResponse<Certificate>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putCertificateItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putCertificateItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vesselcert/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: CertificateToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CertificateFromJSON(jsonValue)
    );
  }

  /**
   * Update certificate
   */
  async putCertificateItem(
    requestParameters: PutCertificateItemRequest
  ): Promise<Certificate> {
    const response = await this.putCertificateItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update drill
   */
  async putDrillItemRaw(
    requestParameters: PutDrillItemRequest
  ): Promise<runtime.ApiResponse<Drill>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putDrillItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putDrillItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/drill/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: DrillToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DrillFromJSON(jsonValue)
    );
  }

  /**
   * Update drill
   */
  async putDrillItem(requestParameters: PutDrillItemRequest): Promise<Drill> {
    const response = await this.putDrillItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update equipment
   */
  async putEquipmentItemRaw(
    requestParameters: PutEquipmentItemRequest
  ): Promise<runtime.ApiResponse<HealthEquipment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putEquipmentItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/health/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: HealthEquipmentToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Update equipment
   */
  async putEquipmentItem(
    requestParameters: PutEquipmentItemRequest
  ): Promise<HealthEquipment> {
    const response = await this.putEquipmentItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update maintenance
   */
  async putMaintenanceItemRaw(
    requestParameters: PutMaintenanceItemRequest
  ): Promise<runtime.ApiResponse<Maintenance>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putMaintenanceItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putMaintenanceItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/maintenance/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: MaintenanceToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceFromJSON(jsonValue)
    );
  }

  /**
   * Update maintenance
   */
  async putMaintenanceItem(
    requestParameters: PutMaintenanceItemRequest
  ): Promise<Maintenance> {
    const response = await this.putMaintenanceItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update part
   */
  async putPartItemRaw(
    requestParameters: PutPartItemRequest
  ): Promise<runtime.ApiResponse<Part>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putPartItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putPartItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/part/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: PartToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PartFromJSON(jsonValue)
    );
  }

  /**
   * Update part
   */
  async putPartItem(requestParameters: PutPartItemRequest): Promise<Part> {
    const response = await this.putPartItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update Repair
   */
  async putRepairItemRaw(
    requestParameters: PutRepairItemRequest
  ): Promise<runtime.ApiResponse<Repair>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putRepairItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putRepairItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/requests/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: RepairToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RepairFromJSON(jsonValue)
    );
  }

  /**
   * Update Repair
   */
  async putRepairItem(
    requestParameters: PutRepairItemRequest
  ): Promise<Repair> {
    const response = await this.putRepairItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update review
   */
  async putReviewItemRaw(
    requestParameters: PutReviewItemRequest
  ): Promise<runtime.ApiResponse<Review>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putReviewItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putReviewItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/review/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ReviewToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReviewFromJSON(jsonValue)
    );
  }

  /**
   * Update review
   */
  async putReviewItem(
    requestParameters: PutReviewItemRequest
  ): Promise<Review> {
    const response = await this.putReviewItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update healthandsafety
   */
  async putSafetyEquipmentItemRaw(
    requestParameters: PutSafetyEquipmentItemRequest
  ): Promise<runtime.ApiResponse<SafetyEquipment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putSafetyEquipmentItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putSafetyEquipmentItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/safetyequipment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SafetyEquipmentToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SafetyEquipmentFromJSON(jsonValue)
    );
  }

  /**
   * Update healthandsafety
   */
  async putSafetyEquipmentItem(
    requestParameters: PutSafetyEquipmentItemRequest
  ): Promise<SafetyEquipment> {
    const response = await this.putSafetyEquipmentItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update survey
   */
  async putSurveyItemRaw(
    requestParameters: PutSurveyItemRequest
  ): Promise<runtime.ApiResponse<Survey>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putSurveyItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putSurveyItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/survey/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SurveyToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SurveyFromJSON(jsonValue)
    );
  }

  /**
   * Update survey
   */
  async putSurveyItem(
    requestParameters: PutSurveyItemRequest
  ): Promise<Survey> {
    const response = await this.putSurveyItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update task
   */
  async putTaskItemRaw(
    requestParameters: PutTaskItemRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putTaskItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putTaskItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/task/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TaskToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Update task
   */
  async putTaskItem(requestParameters: PutTaskItemRequest): Promise<Task> {
    const response = await this.putTaskItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update training
   */
  async putTrainingItemRaw(
    requestParameters: PutTrainingItemRequest
  ): Promise<runtime.ApiResponse<Training>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putTrainingItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putTrainingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/training/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TrainingToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TrainingFromJSON(jsonValue)
    );
  }

  /**
   * Update training
   */
  async putTrainingItem(
    requestParameters: PutTrainingItemRequest
  ): Promise<Training> {
    const response = await this.putTrainingItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update vessel
   */
  async putVesselItemRaw(
    requestParameters: PutVesselItemRequest
  ): Promise<runtime.ApiResponse<Vessel>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putVesselItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putVesselItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/vessel/${encodeURIComponent(
        String(requestParameters.id)
      )}?customer_id=${requestParameters.payload.customerId}`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: VesselToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VesselFromJSON(jsonValue)
    );
  }

  /**
   * Update vessel
   */
  async putVesselItem(
    requestParameters: PutVesselItemRequest
  ): Promise<Vessel> {
    const response = await this.putVesselItemRaw(requestParameters);
    return await response.value();
  }
}
