/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   * User unique identifier.
   * @type {string}
   * @memberof User
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  fullname: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  role: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  readonly customerId: string;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  readonly created?: Date;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    email: json['email'],
    fullname: json['fullname'],
    role: json['role'],
    customerId: json['customer_id'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    fullname: value.fullname,
    role: value.role,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum UserRoleEnum {
  Root = 'root',
  Admin = 'admin',
  Readonly = 'readonly',
}
