/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Meeting,
  MeetingFromJSON,
  MeetingToJSON,
  MeetingPagination,
  MeetingPaginationFromJSON,
  MeetingPaginationToJSON,
} from "../models";

export interface DeleteMeetingItemRequest {
  id: string;
}

export interface GetMeetingItemRequest {
  id: string;
}
export interface GetMeetingListRequest {
  page?: number;
  perPage?: number;
}

export interface PostMeetingListRequest {
  payload: Meeting;
}

export interface PutMeetingItemRequest {
  id: string;
  payload: Meeting;
}

/**
 *
 */
export class MeetingApi extends runtime.BaseAPI {
  /**
   * All related documents will be removed.
   * Delete meeting
   */
  async deleteMeetingItemRaw(
    requestParameters: DeleteMeetingItemRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteMeetingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/meeting/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Get Meeting information
   */
  async getMeetingItemRaw(
    requestParameters: GetMeetingItemRequest
  ): Promise<runtime.ApiResponse<Meeting>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getMeetingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/meeting/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MeetingFromJSON(jsonValue)
    );
  }

  /**
   * All related documents will be removed.
   * Delete Meeting
   */
  async deleteMeetingItem(
    requestParameters: DeleteMeetingItemRequest
  ): Promise<void> {
    await this.deleteMeetingItemRaw(requestParameters);
  }
  /**
   * Get Meeting information
   */
  async getMeetingItem(
    requestParameters: GetMeetingItemRequest
  ): Promise<Meeting> {
    const response = await this.getMeetingItemRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Meeting list
   */
  async getMeetingListRaw(
    requestParameters: GetMeetingListRequest
  ): Promise<runtime.ApiResponse<MeetingPagination>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/meeting/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MeetingPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get Meeting list
   */
  async getMeetingList(
    requestParameters: GetMeetingListRequest
  ): Promise<MeetingPagination> {
    const response = await this.getMeetingListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Meeting
   */
  async postMeetingListRaw(
    requestParameters: PostMeetingListRequest
  ): Promise<runtime.ApiResponse<Meeting>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling postMeetingList."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/meeting/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MeetingToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MeetingFromJSON(jsonValue)
    );
  }

  /**
   * Create new Meeting
   */
  async postMeetingList(
    requestParameters: PostMeetingListRequest
  ): Promise<Meeting> {
    const response = await this.postMeetingListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update meeting
   */
  async putMeetingItemRaw(
    requestParameters: PutMeetingItemRequest
  ): Promise<runtime.ApiResponse<Meeting>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling putMeetingItem."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling putMeetingItem."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/meeting/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: MeetingToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MeetingFromJSON(jsonValue)
    );
  }

  /**
   * Update meeting
   */
  async putMeetingItem(
    requestParameters: PutMeetingItemRequest
  ): Promise<Meeting> {
    const response = await this.putMeetingItemRaw(requestParameters);
    return await response.value();
  }
}
