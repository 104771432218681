/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HealthEquipment
 */
export interface HealthEquipment {
  /**
   * HealthSafety unique identifier.
   * @type {string}
   * @memberof HealthEquipment
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  tag: string;
  /**
   * Due date
   * @type {Date}
   * @memberof HealthEquipment
   */
  dueDate?: Date;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  reminderFrq: string;
  /**
   *
   * @type {boolean}
   * @memberof HealthEquipment
   */
  complete?: boolean;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  notes: string;
  /**
   *
   * @type {boolean}
   * @memberof HealthEquipment
   */
  readonly overdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof HealthEquipment
   */
  readonly customerId?: string;
  /**
   *
   * @type {Date}
   * @memberof HealthEquipment
   */
  readonly created?: Date;
}

export function HealthEquipmentFromJSON(json: any): HealthEquipment {
  return HealthEquipmentFromJSONTyped(json, false);
}

export function HealthEquipmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HealthEquipment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    tag: json["tag"],
    dueDate:
      !exists(json, "due_date") || !json["due_date"]
        ? undefined
        : new Date(json["due_date"]),
    reminderFrq: json["reminder_frq"],
    complete: !exists(json, "complete") ? undefined : json["complete"],
    notes: json["notes"],
    overdue: !exists(json, "overdue") ? undefined : json["overdue"],
    vesselId: json["vessel_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function HealthEquipmentToJSON(value?: HealthEquipment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    tag: value.tag,
    due_date: !value.dueDate ? "" : value.dueDate.toISOString(),
    reminder_frq: value.reminderFrq,
    complete: value.complete,
    notes: !value.notes ? "" : value.notes,
    vessel_id: value.vesselId,
  };
}
