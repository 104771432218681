import { formEvt } from "forms/events";
import { regFormSubmitHandler } from "forms/handlers";
import { setSubmittingFx, validateForm } from "forms/helpers";
import { routeEvent } from "routes/events";
import { fx, regEventFx } from "store";
import { getCurrentUserId } from "users/selectors";
import { uploadToS3 } from "../../util";
import { vesselEvent } from "vessels/events";
import { getSelectedSubItemForm, getSelectedVesselId } from "vessels/selectors";
import { FeedbackFromJSONTyped } from "../../../gen/ts";
import * as R from "ramda";

const validations: Array<{ name: string; rule: string }> = [
  { name: "description", rule: "required" },
];

const submitFileFx = (file: File, vesselId: string) =>
  fx.api(
    ["vessel-document", "create", "one"],
    {
      vesselId,
      objId: vesselId,
      docType: "formrequest",
      replaceDoc: false,
      file,
    },
    {
      onSuccess: "api/s3-presign-feedback-success",
    }
  );

regFormSubmitHandler(["vessel-feedback", "create"], ({ db }, { form }) => {
  const validateResult = validateForm(form, validations);
  if (validateResult === true) {
    const payload = FeedbackFromJSONTyped(form, true);
    payload["vesselId"] = getSelectedVesselId(db);
    return [
      setSubmittingFx(true),
      fx.api(["feedback", "create", "one"], { payload }),
    ];
  } else {
    return [fx.dispatch(formEvt.SET_VALIDATION, { result: validateResult })];
  }
});

regEventFx("api/s3-presign-feedback-success", ({ db }, rsp) => {
  const form = R.path(["forms", "feedbackForm"], db);
  const file = form?.attachment;
  return [
    uploadToS3(rsp, file),
    setSubmittingFx(false),
    fx.dispatch(vesselEvent.HIDE_FEEDBACK_FORM),
  ];
});

regEventFx("api/create-one-feedback-success", ({ db }, { vesselId }) => {
  const form = R.path(["forms", "feedbackForm"], db);
  const file = form?.attachment;
  return [
    ...(file ? [submitFileFx(file, vesselId)] : [setSubmittingFx(false)]),
  ];
  return [setSubmittingFx(false)];
});

regEventFx("api/create-one-feedback-failure", ({ db }, { vesselId, id }) => {
  return [setSubmittingFx(false)];
});
