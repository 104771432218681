/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Task
 */
export interface Task {
  /**
   * Task unique identifier.
   * @type {string}
   * @memberof Task
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  description: string;
  /**
   * Due date
   * @type {Date}
   * @memberof Task
   */
  dueDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Task
   */
  readonly overdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  readonly customerId?: string;
  /**
   *
   * @type {Date}
   * @memberof Task
   */
  readonly created?: Date;
}

export function TaskFromJSON(json: any): Task {
  return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Task {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    title: json['title'],
    description: json['description'],
    dueDate: !exists(json, 'due_date') ? undefined : new Date(json['due_date']),
    overdue: !exists(json, 'overdue') ? undefined : json['overdue'],
    vesselId: json['vessel_id'],
    customerId: !exists(json, 'customer_id') ? undefined : json['customer_id'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
  };
}

export function TaskToJSON(value?: Task | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    due_date:
      value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
    vessel_id: value.vesselId,
  };
}
