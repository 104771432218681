/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI VesselCrewOverdue: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CrewOverdue
 */

interface Item {
  id: string;
  category: string;
  name: string;
  dueDate: Date;
}
function getItemListFromJson(json: any[]): Item[] {
  let result: Array<Item> = [];
  json.map((v: Object) =>
    result.push({
      id: v["id"],
      category: v["category"],
      name: v["name"],
      dueDate: new Date(v["due_date"]),
    })
  );
  return result;
}

interface CrewItem {
  id: string;
  firstname: string;
  lastname: string;
  overdue: Item[];
  overdueCount: number;
  upcoming: Item[];
  upcomingCount: number;
}

function getCrewListFromJson(json: {
  [key: string]: any;
}): { [key: string]: CrewItem } {
  let result = {};
  Object.keys(json).forEach(
    (k) =>
      (result[k] = {
        id: k,
        firstname: json[k].firstname,
        lastname: json[k].lastname,
        overdue: getItemListFromJson(json[k].overdue),
        overdueCount: json[k].overdue_count,
        upcoming: getItemListFromJson(json[k].upcoming),
        upcomingCount: json[k].upcoming_count,
      })
  );
  return result;
}

export interface CrewOverdue {
  /**
   *
   * @type {[]}
   * @memberof CrewOverdue
   */
  crewMembers: [] | { [key: string]: CrewItem };
  /**
   *
   * @type {number}
   * @memberof CrewOverdue
   */
  totalOverdueCount: number;
  /**
   *
   * @type {number}
   * @memberof CrewOverdue
   */
  totalUpcomingCount: number;
}
export function CrewOverdueFromJSONTyped(json: any): CrewOverdue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    crewMembers: !exists(json, "crew_members")
      ? []
      : getCrewListFromJson(json["crew_members"]),
    totalOverdueCount: !exists(json, "total_overdue_count")
      ? 0
      : json["total_overdue_count"],
    totalUpcomingCount: !exists(json, "total_upcoming_count")
      ? 0
      : json["total_upcoming_count"],
  };
}

export function CrewOverdueToJSON(value?: CrewOverdue | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    crew_members: value.crewMembers,
    total_overdue_count: value.totalOverdueCount,
    total_upcoming_count: value.totalUpcomingCount,
  };
}
