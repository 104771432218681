/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Tag,
  TagFromJSON,
  TagPagination,
  TagToJSON,
  TagPaginationFromJSON,
  TagPaginationToJSON,
} from "../models";

export interface GetTagListRequest {
  page?: number;
  perPage?: number;
  customerId?: string;
}

export interface PostTagRequest {
  payload: Tag;
}

export interface PutTagRequest {
  id: string;
  payload: Tag;
}

/**
 *
 */
export class TagApi extends runtime.BaseAPI {
  /**
   * Get Tags list
   */
  async getTagListRaw(
    requestParameters: GetTagListRequest
  ): Promise<runtime.ApiResponse<TagPagination>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters["per_page"] = requestParameters.perPage;
    }

    if (requestParameters.customerId !== undefined) {
      queryParameters["customer_id"] = requestParameters.customerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/tags/`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TagPaginationFromJSON(jsonValue)
    );
  }

  /**
   * Get Tags list
   */
  async getTagList(
    requestParameters: GetTagListRequest
  ): Promise<TagPagination> {
    const response = await this.getTagListRaw(requestParameters);
    return await response.value();
  }

  /**
   * Create new Tags
   */
  async postTagRaw(
    requestParameters: PostTagRequest
  ): Promise<runtime.ApiResponse<Tag>> {
    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling PostTag."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/tags/`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TagToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TagFromJSON(jsonValue)
    );
  }

  /**
   * Create new Tags
   */
  async postTag(requestParameters: PostTagRequest): Promise<Tag> {
    const response = await this.postTagRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update crew
   */
  async putTagRaw(
    requestParameters: PutTagRequest
  ): Promise<runtime.ApiResponse<Tag>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling PutTag."
      );
    }

    if (
      requestParameters.payload === null ||
      requestParameters.payload === undefined
    ) {
      throw new runtime.RequiredError(
        "payload",
        "Required parameter requestParameters.payload was null or undefined when calling PutTag."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["authorization"] = this.configuration.apiKey(
        "authorization"
      ); // apikey authentication
    }

    const response = await this.request({
      path: `/tags/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TagToJSON(requestParameters.payload),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TagFromJSON(jsonValue)
    );
  }

  /**
   * Update crew
   */
  async putTag(requestParameters: PutTagRequest): Promise<Tag> {
    const response = await this.putTagRaw(requestParameters);
    return await response.value();
  }
}
