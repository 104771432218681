/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   * Document unique identifier.
   * @type {string}
   * @memberof Document
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  fileName?: string;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  readonly fileSize?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  mimeType?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly uploadedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly updatedBy?: string;
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  readonly created?: Date;
  /**
   *
   * @type {Date}
   * @memberof Document
   */
  readonly updated?: Date;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly customerId?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly vesselId?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly crewId?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  docType?: string;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  readonly objId?: string;

  /**
   * @type {boolean}
   * @memberof Document
   */
  replaceDoc?: boolean;
}

export function DocumentFromJSON(json: any): Document {
  return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Document {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    fileName: !exists(json, "file_name") ? undefined : json["file_name"],
    fileSize: !exists(json, "file_size") ? undefined : json["file_size"],
    mimeType: !exists(json, "mime_type") ? undefined : json["mime_type"],
    uploadedBy: !exists(json, "uploaded_by") ? undefined : json["uploaded_by"],
    updatedBy: !exists(json, "updated_by") ? undefined : json["updated_by"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
    updated: !exists(json, "updated") ? undefined : new Date(json["updated"]),
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
    vesselId: !exists(json, "vessel_id") ? undefined : json["vessel_id"],
    crewId: !exists(json, "crew_id") ? undefined : json["crew_id"],
    docType: !exists(json, "doc_type") ? undefined : json["doc_type"],
    objId: !exists(json, "obj_id") ? undefined : json["obj_id"],
    replaceDoc: !exists(json, "replace_doc") ? false : json["replace_doc"],
  };
}

export function DocumentToJSON(value?: Document | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    file_name: value.fileName,
    mime_type: value.mimeType,
    doc_type: value.docType,
  };
}
