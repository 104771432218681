import React from "react";
import { theme } from "styles/theme";
import { component, createSub } from "framework-x";
import {
  Flex,
  Text,
  Button,
  Box,
  Stack,
  Icon,
} from "../../components/standalone";
import { getSelectedVesselDetail, getVesselStatuses } from "../selectors";
import * as R from "ramda";
import { getSelectedVesselId } from "../selectors";
import { routeIds } from "routes/events";
import { getRouteId } from "routes/selectors";
import { getCrewStatuses } from "crew/selectors";

export default component(
  "VesselStatusAlert",
  createSub({
    vesselStatuses: getVesselStatuses,
    crewStatuses: getCrewStatuses,
    vessel: getSelectedVesselDetail,
    vesselId: getSelectedVesselId,
    routeId: getRouteId,
  }),
  ({ vesselStatuses, vesselId, routeId, vessel, crewStatuses }) => {
    if (!vesselStatuses) {
      return null;
    }
    let vesselOverdueCount = 0;
    let vesselUpcomingCount = 0;
    let crewOverdueCount = 0;
    let crewUpcomingCount = 0;
    if (routeId == routeIds.VESSEL_LIST) {
      vesselOverdueCount =
        vesselStatuses == {} ? 0 : vesselStatuses.totalOverdueCount;
      vesselUpcomingCount =
        vesselStatuses == {} ? 0 : vesselStatuses.totalUpcomingCount;
    } else if (routeId == routeIds.VESSEL_DETAIL) {
      vesselOverdueCount =
        vesselStatuses == {} || !R.has(vesselId)(vesselStatuses.vessels)
          ? 0
          : vesselStatuses.vessels[vesselId].overdueCount;
      vesselUpcomingCount =
        vesselStatuses == {} || !R.has(vesselId)(vesselStatuses.vessels)
          ? 0
          : vesselStatuses.vessels[vesselId].upcomingCount;
      //Include crew overdue and upcomming count
      const { crew } = vessel;

      R.forEach((item) => {
        crewOverdueCount += R.length(
          R.pathOr([], ["crewMembers", item.id, "overdue"], crewStatuses)
        );
        crewUpcomingCount += R.length(
          R.pathOr([], ["crewMembers", item.id, "upcoming"], crewStatuses)
        );
      }, crew);
    }

    if (
      vesselOverdueCount +
        vesselUpcomingCount +
        crewOverdueCount +
        crewUpcomingCount ==
      0
    )
      return null;

    return (
      <Flex alignItems="center">
        <Flex alignItems={"center"} direction={"column"}>
          <Text fontSize="12px">&nbsp;</Text>
          <Text fontSize="13px" color={theme.colors.gray[500]}>
            Alerts:{" "}
          </Text>
        </Flex>

        {vesselOverdueCount + crewOverdueCount > 0 && (
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"} direction={"column"}>
              <Text fontSize="12px">&nbsp;</Text>
              <Flex alignItems={"center"}>
                <Text
                  fontSize="13px"
                  fontWeight={500}
                  color={theme.colors.red[500]}
                  marginLeft={2}
                >
                  Overdue
                </Text>
              </Flex>
            </Flex>

            {vesselOverdueCount > 0 && (
              <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
                <Box
                  fontSize="12px"
                  fontWeight={500}
                  color={theme.colors.red[500]}
                >
                  Vessel
                </Box>
                <Button
                  size="sm"
                  fontSize="13px"
                  color={theme.colors.red[500]}
                  backgroundColor={`${theme.colors.red[100]} !important`}
                  border={`solid 1px ${theme.colors.red[300]}`}
                  boxShadow={"none !important"}
                  cursor={"default"}
                  marginLeft={0}
                  p={2}
                >
                  {vesselOverdueCount}
                </Button>
              </Flex>
            )}
            {crewOverdueCount > 0 && (
              <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
                <Box
                  fontSize="12px"
                  fontWeight={500}
                  color={theme.colors.red[500]}
                >
                  Crew
                </Box>
                <Button
                  size="sm"
                  fontSize="13px"
                  color={theme.colors.red[500]}
                  backgroundColor={`${theme.colors.red[100]} !important`}
                  border={`solid 1px ${theme.colors.red[300]}`}
                  boxShadow={"none !important"}
                  cursor={"default"}
                  marginLeft={0}
                  p={2}
                >
                  {crewOverdueCount}
                </Button>
              </Flex>
            )}
          </Flex>
        )}
        {vesselUpcomingCount + crewUpcomingCount > 0 && (
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"} direction={"column"}>
              <Text fontSize="12px">&nbsp;</Text>
              <Flex alignItems={"center"}>
                <Text
                  fontSize="13px"
                  fontWeight={500}
                  color={theme.colors.orange[500]}
                  marginLeft={2}
                >
                  Upcoming
                </Text>
              </Flex>
            </Flex>

            {vesselUpcomingCount > 0 && (
              <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
                <Box
                  fontSize="12px"
                  fontWeight={500}
                  color={theme.colors.orange[500]}
                >
                  Vessel
                </Box>
                <Button
                  size="sm"
                  fontSize="13px"
                  color={theme.colors.orange[500]}
                  backgroundColor={`${theme.colors.orange[100]} !important`}
                  border={`solid 1px ${theme.colors.orange[300]}`}
                  boxShadow={"none !important"}
                  cursor={"default"}
                  marginLeft={0}
                  p={2}
                >
                  {vesselUpcomingCount}
                </Button>
              </Flex>
            )}
            {crewUpcomingCount > 0 && (
              <Flex alignItems={"center"} direction={"column"} marginLeft={2}>
                <Box
                  fontSize="12px"
                  fontWeight={500}
                  color={theme.colors.orange[500]}
                >
                  Crew
                </Box>
                <Button
                  size="sm"
                  fontSize="13px"
                  color={theme.colors.orange[500]}
                  backgroundColor={`${theme.colors.orange[100]} !important`}
                  border={`solid 1px ${theme.colors.orange[300]}`}
                  boxShadow={"none !important"}
                  cursor={"default"}
                  marginLeft={0}
                  p={2}
                >
                  {crewUpcomingCount}
                </Button>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    );
  }
);
